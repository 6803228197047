<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="right"
      :model="form.data"
      :rules="form.rules"
    >
      <!-- <el-form-item prop="icon" label="图标：">
         <e-icon-picker v-model="form.data.icon"/>
      </el-form-item>
      <el-form-item prop="cover" label="图片：">
        <my-vue-croppers @crop-success="cropSuccess"></my-vue-croppers>
      </el-form-item> -->
      <el-form-item prop="name" label="姓名：">
        <el-input
          v-model="form.data.name"
          clearable
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="account" label="账号：">
        <el-input
          v-model="form.data.account"
          maxlength="11"
          clearable
          :readonly="isChange"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="isChange ? false : true" :prop="isChange ? '11' : 'password'" label="密码：">
        <el-input
          v-model="form.data.password"
          clearable
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="role_type" label="角色">
          <el-radio :label="1" v-model="form.data.role_type"  @change="handleRadio(form.data.role_type)">管理员</el-radio>
          <el-radio :label="2" v-model="form.data.role_type" @change="handleRadio(form.data.role_type)">员工</el-radio>
      </el-form-item>
      <el-form-item prop="qx" label="权限">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedCities"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox v-for="item in powerArr" :label="item.id" :key="item.id">{{
            item.value
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import MyVueCroppers from "@/components/MyVueCroppers.vue";
  export default {
    name: 'AccountEdit',
    props:['powerArr'],
    components: {
      MyVueCroppers,
    },
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            account: '',
            role_type: 1,
            password: '',
            permission: [],
          },
          rules: {
            name: [{required: true, message: "请输入姓名！", trigger: "change"}],
            account: [{required: true, message: "请输入账号！", trigger: "change"}],
            password: [{required: true, message: "请输入密码！", trigger: "change"}],
            role_type: [{required: true, message: "请选择角色！", trigger: "change"}],
          }
        },
        isIndeterminate: false,
        checkAll: false,
        checkedCities: [],
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.$http.get('/admin/flex_admin/info',{params:{id:row.id}}).then(res => {
          if(res.code === 1) {
            this.form.data = res.data
            let accessObj = this.form.data.permission
            this.checkedCities = [];
            accessObj.forEach(i => {
              this.checkedCities.push(Number(i))
            })
            if(this.checkedCities.length === this.powerArr.length) {
              this.checkAll = true
              this.isIndeterminate = false;
            } else {
              this.checkAll = false
              this.isIndeterminate = true;
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
        this.form.data.permission = [];
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.data.permission = this.form.data.permission.join('|')
            let apiUrl = !this.isChange ? '/admin/flex_admin/add' : '/admin/flex_admin/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
                this.form.data.permission = []
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
      // 选择角色
      handleRadio(role_type) {
        this.isIndeterminate = false;
        this.checkAll = role_type === 1 ? true : false;
        if(role_type === 1) {
          this.powerArr.forEach(v => {
            this.checkedCities.push(v.id)
            this.form.data.permission.push(v.id)
          })
        } else {
          this.checkedCities = []
          this.form.data.permission = []
        }
      },
      // 是否全选
      handleCheckAllChange(val) {
        this.form.data.permission = [];
        if(val === true) {
          this.powerArr.forEach(v => {
            this.checkedCities.push(v.id)
            this.form.data.permission.push(v.id)
          })
        } else {
          this.checkedCities = []
          this.form.data.permission = []
        }
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        this.form.data.permission = [];
        value.forEach(v =>{
          this.form.data.permission.push(v)
        })
        this.checkedCities = value
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.powerArr.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.powerArr.length;
      },

      cropSuccess(data) {
        if (data) {
          this.form.data.cover = data.imgUrl;
        }
      },
    }
  }
</script>
